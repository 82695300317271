@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}

body {
    margin: 0;
}

[type=text],
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

@layer base {
    html {
        font-family: 'Geist';
        font-style: normal;
        font-weight: 400;
        letter-spacing: -2%;
    }

    .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
        border-right-style: hidden;
      }
      .mat-mdc-input-element {
        box-shadow: none !important;
      }
      .mat-mdc-form-field-infix{
        @apply !py-3
      }
      .mat-mdc-text-field-wrapper{
        height: 46px !important;
      }
    /* src: url(/fonts/Roboto.woff2) format('woff2'); */

}


/* Typography Classes */
:root {
  /* Default Typography Variables */
  --typography-h1-size: 2.5rem;
  --typography-h1-weight: 700;
  --typography-h1-line-height: 1.2;
  --typography-h1-letter-spacing: -0.025em;
  --typography-h1-family: 'Geist', sans-serif;

  --typography-h2-size: 2rem;
  --typography-h2-weight: 700;
  --typography-h2-line-height: 1.3;
  --typography-h2-letter-spacing: -0.025em;
  --typography-h2-family: 'Geist', sans-serif;

  --typography-h3-size: 1.75rem;
  --typography-h3-weight: 600;
  --typography-h3-line-height: 1.4;
  --typography-h3-letter-spacing: -0.025em;
  --typography-h3-family: 'Geist', sans-serif;

  --typography-h4-size: 1.5rem;
  --typography-h4-weight: 600;
  --typography-h4-line-height: 1.4;
  --typography-h4-letter-spacing: -0.025em;
  --typography-h4-family: 'Geist', sans-serif;

  --typography-h5-size: 1.25rem;
  --typography-h5-weight: 600;
  --typography-h5-line-height: 1.5;
  --typography-h5-letter-spacing: -0.025em;
  --typography-h5-family: 'Geist', sans-serif;

  --typography-h6-size: 1rem;
  --typography-h6-weight: 600;
  --typography-h6-line-height: 1.5;
  --typography-h6-letter-spacing: -0.025em;
  --typography-h6-family: 'Geist', sans-serif;

  --typography-label-size: 0.875rem;
  --typography-label-weight: 500;
  --typography-label-line-height: 1.5;
  --typography-label-letter-spacing: 0;
  --typography-label-family: 'Geist', sans-serif;

  --typography-p-size: 1rem;
  --typography-p-weight: 400;
  --typography-p-line-height: 1.6;
  --typography-p-letter-spacing: 0;
  --typography-p-family: 'Geist', sans-serif;

   /* Primary */
   --color-primary-50: theme('colors.primary.50');
   --color-primary-100: theme('colors.primary.100');
   --color-primary-200: theme('colors.primary.200');
   --color-primary-300: theme('colors.primary.300');
   --color-primary-400: theme('colors.primary.400');
   --color-primary-500: theme('colors.primary.500');
   --color-primary-600: theme('colors.primary.600');
   --color-primary-700: theme('colors.primary.700');
   --color-primary-800: theme('colors.primary.800');
   --color-primary-900: theme('colors.primary.900');
   --color-primary-950: theme('colors.primary.950');

   /* Secondary */
   --color-secondary-50: theme('colors.secondary.50');
   --color-secondary-100: theme('colors.secondary.100');
   --color-secondary-200: theme('colors.secondary.200');
   --color-secondary-300: theme('colors.secondary.300');
   --color-secondary-400: theme('colors.secondary.400');
   --color-secondary-500: theme('colors.secondary.500');
   --color-secondary-600: theme('colors.secondary.600');
   --color-secondary-700: theme('colors.secondary.700');
   --color-secondary-800: theme('colors.secondary.800');
   --color-secondary-900: theme('colors.secondary.900');
   --color-secondary-950: theme('colors.secondary.950');

   /* Danger */
   --color-danger-50: theme('colors.danger.50');
   --color-danger-100: theme('colors.danger.100');
   --color-danger-200: theme('colors.danger.200');
   --color-danger-300: theme('colors.danger.300');
   --color-danger-400: theme('colors.danger.400');
   --color-danger-500: theme('colors.danger.500');
   --color-danger-600: theme('colors.danger.600');
   --color-danger-700: theme('colors.danger.700');
   --color-danger-800: theme('colors.danger.800');
   --color-danger-900: theme('colors.danger.900');
   --color-danger-950: theme('colors.danger.950');

   /* Success */
   --color-success-50: theme('colors.success.50');
   --color-success-100: theme('colors.success.100');
   --color-success-200: theme('colors.success.200');
   --color-success-300: theme('colors.success.300');
   --color-success-400: theme('colors.success.400');
   --color-success-500: theme('colors.success.500');
   --color-success-600: theme('colors.success.600');
   --color-success-700: theme('colors.success.700');
   --color-success-800: theme('colors.success.800');
   --color-success-900: theme('colors.success.900');
   --color-success-950: theme('colors.success.950');

   /* Warning */
   --color-warning-50: theme('colors.warning.50');
   --color-warning-100: theme('colors.warning.100');
   --color-warning-200: theme('colors.warning.200');
   --color-warning-300: theme('colors.warning.300');
   --color-warning-400: theme('colors.warning.400');
   --color-warning-500: theme('colors.warning.500');
   --color-warning-600: theme('colors.warning.600');
   --color-warning-700: theme('colors.warning.700');
   --color-warning-800: theme('colors.warning.800');
   --color-warning-900: theme('colors.warning.900');
   --color-warning-950: theme('colors.warning.950');

   /* Info */
   --color-info-50: theme('colors.info.50');
   --color-info-100: theme('colors.info.100');
   --color-info-200: theme('colors.info.200');
   --color-info-300: theme('colors.info.300');
   --color-info-400: theme('colors.info.400');
   --color-info-500: theme('colors.info.500');
   --color-info-600: theme('colors.info.600');
   --color-info-700: theme('colors.info.700');
   --color-info-800: theme('colors.info.800');
   --color-info-900: theme('colors.info.900');
   --color-info-950: theme('colors.info.950');

   /* Gray */
   --color-gray-50: theme('colors.gray.50');
   --color-gray-100: theme('colors.gray.100');
   --color-gray-200: theme('colors.gray.200');
   --color-gray-300: theme('colors.gray.300');
   --color-gray-400: theme('colors.gray.400');
   --color-gray-500: theme('colors.gray.500');
   --color-gray-600: theme('colors.gray.600');
   --color-gray-700: theme('colors.gray.700');
   --color-gray-800: theme('colors.gray.800');
   --color-gray-900: theme('colors.gray.900');
   --color-gray-950: theme('colors.gray.950');

   --icon-size-small: 0.875rem;
   --icon-size-normal: 1.1rem;
   --icon-size-large: 1.35rem;
}

/* Typography Classes */
.typography-h1 {
  font-size: var(--typography-h1-size);
  font-weight: var(--typography-h1-weight);
  line-height: var(--typography-h1-line-height);
  letter-spacing: var(--typography-h1-letter-spacing);
  font-family: var(--typography-h1-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-h2 {
  font-size: var(--typography-h2-size);
  font-weight: var(--typography-h2-weight);
  line-height: var(--typography-h2-line-height);
  letter-spacing: var(--typography-h2-letter-spacing);
  font-family: var(--typography-h2-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-h3 {
  font-size: var(--typography-h3-size);
  font-weight: var(--typography-h3-weight);
  line-height: var(--typography-h3-line-height);
  letter-spacing: var(--typography-h3-letter-spacing);
  font-family: var(--typography-h3-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-h4 {
  font-size: var(--typography-h4-size);
  font-weight: var(--typography-h4-weight);
  line-height: var(--typography-h4-line-height);
  letter-spacing: var(--typography-h4-letter-spacing);
  font-family: var(--typography-h4-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-h5 {
  font-size: var(--typography-h5-size);
  font-weight: var(--typography-h5-weight);
  line-height: var(--typography-h5-line-height);
  letter-spacing: var(--typography-h5-letter-spacing);
  font-family: var(--typography-h5-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-h6 {
  font-size: var(--typography-h6-size);
  font-weight: var(--typography-h6-weight);
  line-height: var(--typography-h6-line-height);
  letter-spacing: var(--typography-h6-letter-spacing);
  font-family: var(--typography-h6-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-label {
  font-size: var(--typography-label-size);
  font-weight: var(--typography-label-weight);
  line-height: var(--typography-label-line-height);
  letter-spacing: var(--typography-label-letter-spacing);
  font-family: var(--typography-label-family);
  color: var(--typography-color, var(--text-dark));
}

.typography-p {
  font-size: var(--typography-p-size);
  font-weight: var(--typography-p-weight);
  line-height: var(--typography-p-line-height);
  letter-spacing: var(--typography-p-letter-spacing);
  font-family: var(--typography-p-family);
  color: var(--typography-color, var(--text-dark));
}

ag-grid-angular a {
  font-weight: 700;
  color: var(--color-primary-700)!important;
}

ag-grid-angular a:hover {
  font-weight: 700;
  color: var(--color-primary-950)!important;
}

a {
  color: var(--color-primary-700);
}

a:hover {
  color: var(--color-primary-950);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dnd-list.cdk-drop-list-dragging .dnd-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.order-inp .mat-mdc-text-field-wrapper {
  height: 40px!important;
}

.order-inp .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(40px / 2)!important;
}

.order-inp .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(calc(calc(6.75px + 40px / 2)* -1)) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

:root {
  --p-primary-900: var(--color-primary-900)
}