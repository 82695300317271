// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

html {
  @include mat.theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      typography: Geist,
      density: 0,
    ));

  --mat-datepicker-calendar-container-background-color: #f4f4f4;

 
} 

.mat-mdc-text-field-wrapper {
  z-index: 1;
}